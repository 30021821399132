<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo relative">
        <atlas-logo type="dark" width="250" />

        <!-- <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Reset Password" />
        </div>
      </b-col>
      <!-- /Left Text-->
      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5" v-if="!resetComplete">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Reset your password
          </b-card-title>
          <b-card-text class="mb-2">
            Insert your new passwords in the form below
          </b-card-text>
          <validation-observer ref="resetPasswordForm" #default="{invalid}">
            <b-form class="auth-login-form mt-2" @submit.prevent ref="resetPasswordForm">
              <b-row class="px-0">
                <b-col md="12">
                  <!-- password -->
                  <text-input
                    v-model="password"
                    placeholder=""
                    label="Password"
                    validation-rules="required"
                    size="lg"
                    name="Password"
                    type="password"
                    vid="password"
                  />
                </b-col>
                <b-col md="12">
                  <!-- password -->
                  <text-input
                    v-model="confirmPassword"
                    placeholder=""
                    label="Confirm Password"
                    validation-rules="required|confirmpassword:password"
                    size="lg"
                    name="Confirm Password"
                    type="password"
                  />
                </b-col>

                <b-col cols="12" class="text-center pt-1">
                  <b-form-group v-if="resetPasswordFailed">
                    <form-alert :errors="serverErrors" css="form-alert-simple" variant="none" :show-title="false" />
                  </b-form-group>

                  <!-- submit button -->
                  <b-button variant="primary" type="submit" size="lg" block @click="validateForm">
                    Reset password
                  </b-button>
                </b-col>
              </b-row>
              <!-- form -->
            </b-form>
          </validation-observer>
          <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>

          <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{ name: 'SignUp' }">
              <span>Create an account</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5" v-if="resetComplete">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Thank you
          </b-card-title>
          <b-card-text class="mb-2">
            <p class="h3 font-weight-normal">Your password has been reset. Go to <b-link :to="{ name: 'Login' }">Login</b-link> page.</p>
          </b-card-text>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { accounts } from '@/api/index'
import { required } from '@validations'
import AtlasLogo from '@/components/Layout/Logo/AtlasLogo.vue'
import FormAlert from '@/components/FormAlert/FormAlert.vue'
import FormComponent from '@/mixins/FormComponent'
import { extend } from 'vee-validate'
import { confirmed } from 'vee-validate/dist/rules'

extend('confirmpassword', {
  ...confirmed,
  message: 'The passwords do not match',
})

export default {
  components: {
    AtlasLogo,
    FormAlert,
  },
  mixins: [FormComponent],
  data() {
    return {
      password: '',
      confirmPassword: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      required,
      token: '',
      resetComplete: false,
    }
  },
  created() {
    this.token = this.$route.query.token
    if (!this.token) {
      this.$router.push({ name: 'Login' })
    }
  },
  computed: {
    resetPasswordFailed() {
      return this.serverErrors && this.serverErrors.length
    },
    imgUrl() {
      if (this.$store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    async submit() {
      const self = this

      let data = {
        token: self.token,
        password: self.password,
      }

      await accounts
        .resetPassword(data)
        .then(res => {
          self.ResetErrors()
          self.resetComplete = true
        })
        .catch(err => {
          if (err.response.status == 422) {
            self.HandleUnprocessableEntity(err)
          } else {
            self.genericError = true
          }
        })
    },
    async validateForm(e) {
      e.preventDefault()
      let self = this

      self.$refs.resetPasswordForm.validate().then(success => {
        if (success) {
          self.submit()
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

// .sign-up {
//   width: 600px;
// }

// .auth-wrapper {
//   min-height: calc(var(--vh, 0.8vh) * 100);

//   &.auth-v1 {
//     .auth-inner {
//       width: 800px;
//     }

//     .auth-inner:before,
//     .auth-inner:after {
//       background-image: none;
//     }
//   }
// }
</style>
