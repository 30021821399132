<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo relative">
        <atlas-logo type="dark" width="250" />

        <!-- <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Forgot Password" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot Password -->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5" v-if="insertUsername">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Reset your password
          </b-card-title>
          <b-card-text class="mb-2">
            Insert your username, we will send the link and the instruction to reset it
          </b-card-text>
          <validation-observer ref="forgotPasswordForm" #default="{invalid}">
            <b-form class="auth-login-form mt-2" @submit.prevent ref="forgotPasswordForm">
              <b-row class="px-0">
                <b-col md="12">
                  <!-- username -->
                  <b-form-group label-for="username" label="Username">
                    <validation-provider #default="{ errors }" name="Username" rules="required|email">
                      <b-form-input
                        id="username"
                        size="lg"
                        v-model="username"
                        name="username"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Type a valid email address..."
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12" class="text-center pt-1">
                  <b-form-group v-if="hasServerErrors">
                    <form-alert :errors="serverErrors" :generic-error="genericError" css="form-alert-simple" variant="none" :show-title="false" />
                  </b-form-group>

                  <!-- submit button -->
                  <b-button variant="primary" type="submit" size="lg" block @click="validateForm">
                    Reset password
                  </b-button>
                </b-col>
              </b-row>
              <!-- form -->
            </b-form>
          </validation-observer>
          <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>
          <b-card-text class="text-center mt-2">
            <div>
              <span>New on our platform? </span>
              <b-link :to="{ name: 'SignUp' }">
                <span>Create an account</span>
              </b-link>
            </div>
            <div>
              <span
                >Go back to
                <b-link :to="{ name: 'Login' }">
                  <span>Login</span>
                </b-link>
                page</span
              >
            </div>
          </b-card-text>
        </b-col>
      </b-col>
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5" v-if="!insertUsername">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Thank you
          </b-card-title>
          <b-card-text class="mb-2">
            <p class="h3 font-weight-normal">
              Check your email for instructions to reset your password. If you haven't received an email in 5 minutes, check your spam or
              <b-link @click="onRetry">retry</b-link>.
            </p>
          </b-card-text>
          <div class="divider my-2">
            <div class="divider-text">or</div>
          </div>
          <b-card-text class="text-center mt-2">
            <div>
              <span>New on our platform? </span>
              <b-link :to="{ name: 'SignUp' }">
                <span>Create an account</span>
              </b-link>
            </div>
            <div>
              <span
                >Go back to
                <b-link :to="{ name: 'Login' }">
                  <span>Login</span>
                </b-link>
                page</span
              >
            </div>
          </b-card-text>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { accounts } from '@/api/index'
import { required, email } from '@validations'
import AtlasLogo from '@/components/Layout/Logo/AtlasLogo.vue'
import FormComponent from '@/mixins/FormComponent'
import FormAlert from '@/components/FormAlert/FormAlert.vue'

export default {
  components: {
    AtlasLogo,
    FormAlert,
  },
  mixins: [FormComponent],
  data() {
    return {
      username: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      required,
      email,
      insertUsername: true,
    }
  },
  computed: {
    forgotPasswordFailed() {
      return this.serverErrors && this.serverErrors.length
    },
    imgUrl() {
      if (this.$store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    async submit() {
      const self = this

      let data = {
        username: this.username,
      }

      await accounts
        .forgotPassword(data)
        .then(res => {
          self.ResetErrors()
          self.insertUsername = false
        })
        .catch(err => {
          if (err.response.status == 422) {
            self.HandleUnprocessableEntity(err)
          } else {
            self.genericError = true
          }
        })
    },
    async validateForm(e) {
      e.preventDefault()
      let self = this

      self.$refs.forgotPasswordForm.validate().then(success => {
        if (success) {
          self.submit()
        }
      })
    },
    onRetry() {
      this.username = ''
      this.insertUsername = true
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

// .sign-up {
//   width: 600px;
// }

// .auth-wrapper {
//   min-height: calc(var(--vh, 0.8vh) * 100);

//   &.auth-v1 {
//     .auth-inner {
//       width: 800px;
//     }

//     .auth-inner:before,
//     .auth-inner:after {
//       background-image: none;
//     }
//   }
// }
</style>
