var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo relative"},[_c('atlas-logo',{attrs:{"type":"dark","width":"250"}})],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Sign Up"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Start building for free ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Make your contents management easy and fun! ")]),_c('validation-observer',{ref:"signUpForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{ref:"signUpForm",staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',{staticClass:"px-0"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"firstname","label":"First Name"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstname","size":"lg","name":"firstname","state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.account.firstName),callback:function ($$v) {_vm.$set(_vm.account, "firstName", $$v)},expression:"account.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"lastname","label":"Last Name"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastname","size":"lg","name":"lastname","state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.account.lastName),callback:function ($$v) {_vm.$set(_vm.account, "lastName", $$v)},expression:"account.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"company","label":"Company"}},[_c('validation-provider',{attrs:{"name":"Company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company","size":"lg","name":"company","state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.account.company),callback:function ($$v) {_vm.$set(_vm.account, "company", $$v)},expression:"account.company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"jobtype","label":"Job Type"}},[_c('validation-provider',{attrs:{"name":"Job Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.jobTypes,"size":"lg"},model:{value:(_vm.account.jobType),callback:function ($$v) {_vm.$set(_vm.account, "jobType", $$v)},expression:"account.jobType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"username","label":"Email"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","size":"lg","name":"username","state":errors.length > 0 ? false : null,"placeholder":"Type a valid email address..."},model:{value:(_vm.account.username),callback:function ($$v) {_vm.$set(_vm.account, "username", $$v)},expression:"account.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('text-input',{attrs:{"placeholder":"","label":"Password","validation-rules":"required","size":"lg","type":"password","vid":"password"},model:{value:(_vm.account.password),callback:function ($$v) {_vm.$set(_vm.account, "password", $$v)},expression:"account.password"}})],1),_c('b-col',{staticClass:"pt-1"},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":{ isEqual: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"name":"privacy","id":"privacy"},model:{value:(_vm.privacy),callback:function ($$v) {_vm.privacy=$$v},expression:"privacy"}},[_vm._v(" I agree to the "),_c('b-link',{attrs:{"href":"https://www.atlascms.io/terms","target":"_blank"}},[_vm._v("terms of services")]),_vm._v(" and "),_c('b-link',{attrs:{"href":"https://www.atlascms.io/privary","target":"_blank"}},[_vm._v("privacy policy")]),_vm._v(". ")],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"text-center pt-1",attrs:{"cols":"12"}},[(_vm.hasErrors)?_c('b-form-group',[_c('form-alert',{attrs:{"errors":_vm.serverErrors,"css":"form-alert-simple","variant":"none","show-title":false}})],1):_vm._e(),_c('b-button',{attrs:{"variant":"primary","type":"submit","size":"lg","block":""},on:{"click":_vm.validateForm}},[_vm._v(" Sign up ")])],1)],1)],1)]}}])}),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_vm._v(" or ")])]),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already have an account? ")]),_c('b-link',{attrs:{"to":{ name: 'Login' }}},[_c('span',[_vm._v("Login")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }