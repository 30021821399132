<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo relative">
        <atlas-logo type="dark" width="250" />

        <!-- <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Sign Up" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Start building for free
          </b-card-title>
          <b-card-text class="mb-2">
            Make your contents management easy and fun!
          </b-card-text>
          <validation-observer ref="signUpForm" #default="{invalid}">
            <b-form class="auth-login-form mt-2" @submit.prevent ref="signUpForm">
              <b-row class="px-0">
                <b-col md="12">
                  <!-- firstname -->
                  <b-form-group label-for="firstname" label="First Name">
                    <validation-provider #default="{ errors }" name="First Name" rules="required">
                      <b-form-input
                        id="firstname"
                        size="lg"
                        v-model="account.firstName"
                        name="firstname"
                        :state="errors.length > 0 ? false : null"
                        placeholder=""
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <!-- lastname -->
                  <b-form-group label-for="lastname" label="Last Name">
                    <validation-provider #default="{ errors }" name="Last Name" rules="required">
                      <b-form-input
                        id="lastname"
                        size="lg"
                        v-model="account.lastName"
                        name="lastname"
                        :state="errors.length > 0 ? false : null"
                        placeholder=""
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <!-- company -->
                  <b-form-group label-for="company" label="Company">
                    <validation-provider #default="{ errors }" name="Company" rules="required">
                      <b-form-input id="company" size="lg" v-model="account.company" name="company" :state="errors.length > 0 ? false : null" placeholder="" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <!-- job type -->
                  <b-form-group label-for="jobtype" label="Job Type">
                    <validation-provider #default="{ errors }" name="Job Type" rules="required">
                      <b-form-select v-model="account.jobType" :options="jobTypes" size="lg" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <!-- username -->
                  <b-form-group label-for="username" label="Email">
                    <validation-provider #default="{ errors }" name="Username" rules="required|email">
                      <b-form-input
                        id="username"
                        size="lg"
                        v-model="account.username"
                        name="username"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Type a valid email address..."
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <!-- password -->
                  <text-input v-model="account.password" placeholder="" label="Password" validation-rules="required" size="lg" type="password" vid="password" />
                </b-col>

                <b-col class="pt-1">
                  <b-form-group>
                    <validation-provider #default="{ errors }" :rules="{ isEqual: true }">
                      <b-form-checkbox v-model="privacy" name="privacy" id="privacy">
                        I agree to the
                        <b-link href="https://www.atlascms.io/terms" target="_blank">terms of services</b-link>
                        and
                        <b-link href="https://www.atlascms.io/privary" target="_blank">privacy policy</b-link>.
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" class="text-center pt-1">
                  <b-form-group v-if="hasErrors">
                    <form-alert :errors="serverErrors" css="form-alert-simple" variant="none" :show-title="false" />
                  </b-form-group>

                  <!-- submit button -->
                  <b-button variant="primary" type="submit" size="lg" block @click="validateForm">
                    Sign up
                  </b-button>
                </b-col>
              </b-row>
              <!-- form -->
            </b-form>
          </validation-observer>
          <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>

          <!-- <div class="auth-footer-btn d-flex justify-content-center">
          <b-button href="javascript:void(0)" variant="facebook">
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button href="javascript:void(0)" variant="twitter">
            <feather-icon icon="TwitterIcon" />
          </b-button>
          <b-button href="javascript:void(0)" variant="google">
            <feather-icon icon="MailIcon" />
          </b-button>
          <b-button href="javascript:void(0)" variant="github">
            <feather-icon icon="GithubIcon" />
          </b-button>
        </div> -->

          <b-card-text class="text-center mt-2">
            <span>Already have an account? </span>
            <b-link :to="{ name: 'Login' }">
              <span>Login</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- <b-col lg="5" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Start building for free
          </b-card-title>
          <b-card-text class="mb-2">
            Make your app management easy and fun!
          </b-card-text>
        </b-col>
      </b-col> -->
    </b-row>
  </div>
</template>

<script>
import { accounts } from '@/api/index'
import { mapActions } from 'vuex'
import { required, email } from '@validations'
import AtlasLogo from '@/components/Layout/Logo/AtlasLogo.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import FormAlert from '@/components/FormAlert/FormAlert.vue'
import { extend } from 'vee-validate'
import FormComponent from '@/mixins/FormComponent'

extend('isEqual', {
  message: field => 'You must agree to the terms of services and privacy policy.',
  validate: value => value,
})

export default {
  components: {
    AtlasLogo,
    FormAlert,
  },
  mixins: [FormComponent, togglePasswordVisibility],
  data() {
    return {
      account: { jobType: '' },
      passwordFieldType: 'password',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      privacy: false,
      required,
      email,
      jobTypes: [
        { value: '', text: 'Choose..' },
        { value: 'Director', text: 'Director' },
        { value: 'Developer/Engineering/Programming', text: 'Developer/Engineering/Programming' },
        { value: 'Graphic/UI/UX Designer', text: 'Graphic/UI/UX Designer' },
        { value: 'Marketing', text: 'Marketing' },
        { value: 'Product Owner/Project Manager', text: 'Product Owner/Project Manager' },
        { value: 'Other', text: 'Other' },
      ],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (this.$store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    ...mapActions(['SetAuthToken']),
    async submit() {
      const self = this

      accounts
        .registerAccount(self.account)
        .then(res => {
          self.ResetErrors()

          self.SetAuthToken(res.data)

          window.location.href = '/'
        })
        .catch(err => {
          if (err.response.status == 422) {
            self.HandleUnprocessableEntity(err)
          } else {
            self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
          }
        })
    },
    async validateForm(e) {
      e.preventDefault()

      let self = this
      self.$refs.signUpForm.validate().then(success => {
        if (success) {
          self.submit()
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

// .sign-up {
//   width: 600px;
// }

// .auth-wrapper {
//   min-height: calc(var(--vh, 0.8vh) * 100);

//   &.auth-v1 {
//     .auth-inner {
//       width: 800px;
//     }

//     .auth-inner:before,
//     .auth-inner:after {
//       background-image: none;
//     }
//   }
// }
</style>
